<template>
  <v-content>
    <v-container fill-height>
      <v-layout align-center justify-center class="signin_layout">
        <div class="register_wrapper">
            <div class="register_header_wrapper">
              <div class="register_header">
                <img src="@/assets/img/bici/logo.png" width="40"/>
                <div class="register_title">
                  <small>CRISP | CP</small>
                  <span>{{ title }}</span>
                </div>
              </div>
              <div class="back_button">
                <router-link to="/SignIn"><i class="mdi mdi-arrow-left"></i></router-link>
              </div>
            </div>

            <v-form v-if="!requestApplied" v-model="valid" ref="form">
              <div class="register_box_wrapper">          
                <div class="register_box">
                  <div class="input_fields">
                    <v-text-field 
                      v-model="selectedItem.email"
                      required
                      name="email" 
                      label="Email" 
                      type="text"
                      :rules="emailRules"
                    ></v-text-field>
                    <v-text-field
                      v-model="selectedItem.password"
                      outlined
                      required
                      name="password"
                      label="Password"
                      type="password"
                      :rules="passwordRules"
                    ></v-text-field>
                    <v-text-field
                      v-model="confirm"
                      outlined
                      required
                      name="confirm"
                      label="Confirm Password"
                      type="password"
                      :rules="confirmationRules"
                    ></v-text-field>
                  </div>
                </div>
                <div class="register_box">
                  <div class="input_fields">
                    <v-text-field 
                      v-model="selectedItem.userName" 
                      required
                      name="name" 
                      label="Name" 
                      type="text"
                      :rules="defaultRules"
                    ></v-text-field>
                    <v-select
                      v-model="selectedItem.office" 
                      name="company" 
                      label="Company" 
                      type="text"
                      :items="officeName"
                      :rules="defaultRules"
                    ></v-select>
                    <v-text-field 
                      v-model="selectedItem.position" 
                      name="position" 
                      label="Position" 
                      type="text"
                      :rules="defaultRules"
                    ></v-text-field>
                  </div>
                  <div class="button_wrapper">
                    <v-btn color="primary" @click="onApply">APPLY</v-btn>
                  </div>
                </div>
              </div>
            </v-form>

            <div v-else class="apply_text">
              <p>
                Your request has been sent to the account admin, <br>
                if your request is approved,<br> you will receive an email allowing you to login to the system.<br>
                Thank you for your patience!
              </p>
              <p>
                您的请求已发送给帐户管理员，如果您的请求被批准，<br>
                您将收到一封电子邮件，允许您登录系统。<br>
                感谢您的耐心等待！
              </p>
            </div>

          </div>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import '@/assets/stylus/ui/component/_register.styl'
import { ApplicationService }  from "@/services"
import CompanyItem from "@/mixins/staticOptions"
export default {
  name: 'register',
  mixins: [
    CompanyItem
  ],
  data: () => ({
    uniq : null,
    CompanyNames: null,
    title: 'Request Permission',
    selectedItem: {},
    requestApplied: false,
    accountService: null,
    applicationService: null,
    confirm: '',
    valid: false,
    defaultRules: [v => !!v || 'Required'],
    passwordRules: [
      v => !!v || 'Required',
      v => (!!v && v.length >= 5) || 'At leat 5 characters required'
    ],
    confirmationRules: [
      v => !!v || 'Required',
      v => (!!v && v.length >= 5) || 'At leat 5 characters required'
    ],
    emailRules: [
      v => !!v || 'Required',
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail is not valid'
    ],
  }),
  computed: {

  },
  created() {
    this.confirmationRules.push(this.confRule)
    this.applicationService = new ApplicationService()
  },
  methods: {
  
    onApply() {
      this.$refs.form.validate()

      if(!this.valid) return

      this.selectedItem.id = this.selectedItem.email

      this.applicationService.uniqMember(this.selectedItem.id).then(res => {
        
        if (res > 0) {
          this.requestApplied = false
          this.title = 'Email already exists.'
        }else {
          this.selectedItem.approval = "WAITING"
          this.applicationService.reqMember(this.selectedItem, res => {
          })
          this.requestApplied = true
          this.title = 'Request Completed'
        }
      })

    },
    confRule() {
      return this.selectedItem.password === this.confirm || 'Passwords do not match'
    }
  }
}
</script>